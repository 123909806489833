import './App.css';
import { useState } from 'react';
import gatherResponse from './fetch-helper';

const loginIn = async(login, password) => {
    const url = 'https://cors-worker.yuliia-bozhek.workers.dev';
    // const url = 'http://127.0.0.1:8787';

    return await fetch(url, {
        method : 'POST',
        body : JSON.stringify({ username: login, password }),
        headers : { 'Content-type' : 'application/json' },
    });
};

function App() {
    const [login, setLogin] = useState('postman');
    const [password, setPassword] = useState('password');

    const [result, setResult] = useState('please, sign in');

    const sendLoginRequest = async() => {
        const results = await loginIn(login, password);
        const res = await gatherResponse(results);
        setResult(res);
    };

    const updateLogin = evt => setLogin(evt.target.value);
    const updatePassword = evt => setPassword(evt.target.value);

    return (
        <div className="App">
            <div className="form">
                <input id="login" type="text" onChange={updateLogin} placeholder="User login"/>
                <input id="password" type="password" onChange={updatePassword} placeholder="Password"/>
                <button onClick={sendLoginRequest}>Login</button>
            </div>

            <p>{result}</p>
        </div>
    );
}

export default App;
